import React, { useEffect, useRef, useState } from "react";
import { Link } from "gatsby"
import { Layout, FiltersNav } from '../components';
import { Helmet } from "react-helmet";
import { ResponsiveImage } from "../components"
import { getArtworks, formatCCFixed, getDrops, getArtists } from "../components/shared/Utils";
import moment from "moment";
import { Select2 } from "select2-react-component";



// markup
const Drops = ({ location }) => {

	const [hash, setHash] = useState(null);

	const [dropsLoader, setDropsLoader] = useState(null);
	const [artworksLoader, setArtworksLoader] = useState(null);
	const [artworksList, setArtworksList] = useState([]);

	const [dropsOrderBy, setDropsOrderBy] = useState('d');
	const [dropsOrderDir, setDropsOrderDir] = useState('desc');

	const [dropsList, setDropsList] = useState(null);
	const [artistsList, setArtistsList] = useState(null);

	const dropsListSelect = useRef(null);
	const artistsListSelect = useRef(null);

	const [artworksFilterDrop, setArtworksFilterDrop] = useState(null);
	const [artworksFilterArtist, setArtworksFilterArtist] = useState(null);

	const [filters, setFilters] = useState({});

	const [selectedDrop, setSelectedDrop] = useState(null);

	const sortList = [
		{ value: 'd_desc', label: 'Most recent first' },
		{ value: 'd_asc', label: 'Oldest first' },
		{ value: 'n_asc', label: 'Name Ascending' },
		{ value: 'n_desc', label: 'Name Descending' },
	];

	useEffect(() => {
		/* let _hash = location.hash;
		_hash = _hash.indexOf('#!/') === 0 ? _hash.substr(3) : false;
		setHash(_hash);

		let _filters = {};
		if (_hash && _hash.indexOf('filters') !== -1) {
			let filter_str,
				filters_re = /filters\[(\w+)\]=([\w-]+)/g;
			while ((filter_str = filters_re.exec(_hash)) !== null) {
				_filters[filter_str[1]] = filter_str[2];
			}
			console.log({ _filters });
		} */

		let params = location.search.indexOf('?') === 0 ? location.search.substring(1).split('&') : [];
		params = params.map((param) => {
			return param.split('=');
		});

		let _filters = {};
		if (params.length) {
			for (let i = 0, l = params.length; i < l; i++) {
				_filters[params[i][0]] = params[i][1];
			}
			console.log({_filters});
		}

		setFilters(_filters);

		getDrops(setDropsLoader, { limit: -1, order_by: 'dl', order_direction: 'asc', asset_limit: 0 });
		getArtworks(setArtworksLoader, { limit: 11, order_by: 'd', order_direction: 'desc', group_by: 'drop', group_order_by: dropsOrderBy, group_order_direction: dropsOrderDir, filters: _filters });
		getArtists(setArtistsList, { limit: -1, order_by: 'n', order_direction: 'asc' });
		getDrops(setDropsList, { limit: -1, order_by: 'dl', order_direction: 'asc' });


		return () => {
			document.querySelector("html").classList.remove("no-scroll");
			document.querySelector("body").classList.remove("no-scroll");
		}
	}, []);

	useEffect(() => {
		console.log('Location changed', {location, 'location.search !== \'\'': location.search !== ''});
		// don't have the drop detail filter
		let _filters = {};
		if (location.search !== '') {
			let params = location.search.indexOf('?') === 0 ? location.search.substring(1).split('&') : [];
			params = params.map((param) => {
				return param.split('=');
			});

			if (params.length) {
				for (let i = 0, l = params.length; i < l; i++) {
					_filters[params[i][0]] = params[i][1];
				}
				console.log({_filters});
			}
		}
		console.log({filters, 'newFilters': _filters});
		setFilters(_filters);
	}, [location]);

	useEffect(() => {
		console.log('dropsLoader or filters CHANGED', {dropsLoader, filters});
		if (dropsLoader != null && filters.drop) {
			let _selectedDrop = null;
			for (let i in dropsLoader.i) {
				if (dropsLoader.i[i].slug == filters.drop) {
					_selectedDrop = dropsLoader.i[i];
				}
			}
			setSelectedDrop(_selectedDrop);
			console.log({'dropsListSelect': dropsListSelect.current});
			dropsListSelect.current.option = {
				value: _selectedDrop.slug,
				label: 'Drop #' + _selectedDrop.number + ' - ' + _selectedDrop.name,
			};
		} else {
			setSelectedDrop(null);
			dropsListSelect.current.option = {
				value: '-',
				label: 'All drops',
			};
		}
	}, [dropsLoader, filters]);

	/* useEffect(() => {
		let current_value = {
			label: '-',
			value: 'Loading...',
		};

		console.log('dropsListSelect', dropsListSelect.current, dropsListSelect.current.value);
		if (dropsLoader != null && dropsListSelect.current.value != null) {
			let _selectedDrop = null;
			for (let i in dropsLoader.i) {
				if (dropsLoader.i[i].slug == dropsListSelect.current.value.slug) {
					_selectedDrop = dropsLoader.i[i];
				}
			}
			setSelectedDrop(_selectedDrop);
			if (_selectedDrop != null) {
				current_value.value = _selectedDrop.slug;
				current_value.label = 'Drop #' + _selectedDrop.number + ' - ' + _selectedDrop.name;
			}
		}

		dropsListSelect.current.option = current_value;
	}, [dropsLoader]); */

	useEffect(() => {
		if (artworksLoader == null) {
			return;
		}

		/* let temp = [];

		for (let i = 0, l = artworksLoader.c; i < l; i++) {
			let currentItem = artworksLoader.i[i];

			// when the array is empty in the beginning OR when the previous item's Drop is different from the currentItem's Drop,
			// add the Drop to the list
			if (
				(artworksList.length === 0 && temp.length === 0)
				|| (artworksList.length > 0 && currentItem.drop.slug !== artworksList[artworksList.length - 1].drop.slug)
			) {
				temp.push(currentItem.drop);
			}

			temp.push(currentItem);
		}

		setArtworksList(artworksList.concat(temp)); */
		artworksLoader.i !== false && setArtworksList(artworksList.concat(artworksLoader.i));

		// console.log('New artworksList:', {artworksList: artworksList.concat(temp)});
	}, [artworksLoader]);

	const onLoadMoreArtworks = () => {
		getArtworks(setArtworksLoader, { offset: artworksList.length, limit: 12, order_by: 'd', order_direction: 'desc', group_by: 'drop', group_order_by: dropsOrderBy, group_order_direction: dropsOrderDir, filters: filters });
	}

	const onToggleFilters = (evt) => {
		console.log(evt.target.checked);

		if (evt.target.checked) {
			document.querySelector("html").classList.add("no-scroll");
			document.querySelector("body").classList.add("no-scroll");
		} else {
			document.querySelector("html").classList.remove("no-scroll");
			document.querySelector("body").classList.remove("no-scroll");
		}
	}

	const onChangeFilterDrop = (value) => {
		let real_value = value == '-' ? null : value;
		setArtworksFilterDrop(real_value);
		setArtistsList(null);
		getArtists(setArtistsList, { limit: -1, order_by: 'n', order_direction: 'asc', filters: { drop: real_value } });

		let _selectedDrop = null;
		if (real_value != null) {
			for (let i in dropsLoader.i) {
				if (dropsLoader.i[i].slug == real_value) {
					_selectedDrop = dropsLoader.i[i];
				}
			}
		}
		setSelectedDrop(_selectedDrop);
	};

	const onChangeFilterArtist = (value) => {
		console.log('Artist selected: ' + value);
		let real_value = value == '-' ? null : value;
		setArtworksFilterArtist(real_value);
		setDropsList(null);
		setSelectedDrop(null);
		getDrops(setDropsList, { limit: -1, order_by: 'dl', order_direction: 'asc', filters: { artist: real_value } });
	};

	const onChangeFiltersSort = (value) => {
		let value_parts = value.split('_')
		setDropsOrderBy(value_parts[0]);
		setDropsOrderDir(value_parts[1]);
	}

	const onSubmitFilters = () => {
		setArtworksLoader(null);
		setArtworksList([]);
		getArtworks(setArtworksLoader, {
			offset: 0, limit: 12, order_by: 'd', order_direction: 'desc', group_by: 'drop', group_order_by: dropsOrderBy, group_order_direction: dropsOrderDir, filters: {
				drop: artworksFilterDrop,
				artist: artworksFilterArtist,
			}
		});
	};

	return (
		<Layout location={location}>
			<Helmet>
				<link href="/assets/css/collections.css" rel="stylesheet" type="text/css" />
				{selectedDrop != null ? (
					<title>
						Ephemeral/Ethernal - {selectedDrop.name}
					</title>
				) : (
					<title>
						Ephemeral/Ethernal - Drops
					</title>
				)}
			</Helmet>


			<section data-name="layout" id={'drops_page'}>
				<div className={'page-container'}>

					<FiltersNav location={location} items={dropsLoader == null ? '...' : (artworksFilterDrop != null ? 1 : dropsLoader.c)} />

					<div className={'collections-bg p-0'}></div>
					<div className={'collections-container container-fluid d-flex align-items-center flex-column'}>

						<div className={'page-title col-12 col-md-11'}>

							{selectedDrop != null ? (
								<>
									<h1 className="collections-title pb-4">
										{selectedDrop.name}
									</h1>
									<div className={'drop-description'} dangerouslySetInnerHTML={{ __html: selectedDrop.description.replace(/\n(\r)?/g, '<br />') }}></div>
								</>
							) : (
								<h1 className="collections-title pb-4">
									DR<br />OPS
								</h1>
							)}
						</div>

						<div className={'col-11 cards-container'}>

							{dropsLoader == null || artworksLoader == null ? (
								<>
									<div className={'content-placeholder my-4 col-12 col-md-6 col-xl-4 col-xxl-3'}>
										<div className={'card w-100'}>
											<div className={'dummy-img gradient'}></div>
											<div className={'dummy-title gradient'}>&nbsp;</div>
											<div className={'dummy-description gradient'}>&nbsp;</div>
											<div className={'dummy-artist'}>
												<div className={'circle gradient'}>&nbsp;</div>
												<div className={'artist-name gradient'}>&nbsp;</div>
											</div>
										</div>
									</div>

									<div className={'content-placeholder my-4 col-12 col-md-6 col-xl-4 col-xxl-3 d-none d-md-block'}>
										<div className={'card w-100'}>
											<div className={'dummy-img gradient'}>&nbsp;</div>
											<div className={'dummy-title gradient'}>&nbsp;</div>
											<div className={'dummy-description gradient'}>&nbsp;</div>
											<div className={'dummy-artist'}>
												<div className={'circle gradient'}>&nbsp;</div>
												<div className={'artist-name gradient'}>&nbsp;</div>
											</div>
										</div>
									</div>

									<div className={'content-placeholder my-4 col-12 col-md-6 col-xl-4 col-xxl-3 d-none d-xl-block'}>
										<div className={'card w-100'}>
											<div className={'dummy-img gradient'}>&nbsp;</div>
											<div className={'dummy-title gradient'}>&nbsp;</div>
											<div className={'dummy-description gradient'}>&nbsp;</div>
											<div className={'dummy-artist'}>
												<div className={'circle gradient'}>&nbsp;</div>
												<div className={'artist-name gradient'}>&nbsp;</div>
											</div>
										</div>
									</div>

									<div className={'content-placeholder my-4 col-12 col-md-6 col-xl-4 col-xxl-3 d-none d-xxl-block'}>
										<div className={'card w-100'}>
											<div className={'dummy-img gradient'}>&nbsp;</div>
											<div className={'dummy-title gradient'}>&nbsp;</div>
											<div className={'dummy-description gradient'}>&nbsp;</div>
											<div className={'dummy-artist'}>
												<div className={'circle gradient'}>&nbsp;</div>
												<div className={'artist-name gradient'}>&nbsp;</div>
											</div>
										</div>
									</div>
								</>
							) : (
								<>
									{/* {selectedDrop != null ? (
										<div className={'drop-info-container'}>
											<div className={'drop-info-title'}>
												{selectedDrop.description}
											</div>
										</div>
									) : (null)} */}
									{artworksLoader.i ? (
										artworksList.map((artwork, index) => {
											let html = [];

											if (index === 0 || artwork.drop.slug !== artworksList[index - 1].drop.slug) {
												html.push(<div key={`spacer_${index}`} className="col-12"></div>);
												html.push(
													<div key={`drop_${index}`} className={'card my-4 col-12 col-md-6 col-xl-4 col-xxl-3'}>
														<Link to={`/drops/?drop=${artwork.drop.slug}`} className={'collection-drop separator col-12'}>
															<span className={'date'}>{moment.utc(artwork.drop.drop_launch).format('DD MMM, YYYY')}<br /></span>
															<span className={'drop'}>Drop<br />#{(artwork.drop.number < 10 ? '0' : '') + artwork.drop.number}</span>
														</Link>
													</div>
												);
											}

											html.push(
												<div key={index} className={'card my-4 col-12 col-md-6 col-xl-4 col-xxl-3'}>
													<Link to={`/drops/?drop=${artwork.drop.slug}`} className={'collection-drop-banner'}><strong>Drop #{(artwork.drop.number < 10 ? '0' : '') + artwork.drop.number}</strong>&nbsp;/&nbsp;{moment.utc(artwork.drop.drop_launch).format('DD MMM, YYYY')}</Link>
													<div className={'collection-img'}>

														<a href={artwork.permalink} rel="noopener" target="_blank" >
															<div className={'open-hover-fx'}>Open</div>
															<ResponsiveImage
																initialSrc={artwork.image_url}
																alt={"Artwork Image"}
															/>
														</a>
													</div>
													<div className={'collection-title'}>{artwork.short_name}</div>
													<div className={'collection-description'}>{artwork.description}</div>
													<Link to={'/artists/detail/?' + artwork.artist.slug} className={'collection-artist'}>
														<div className={'avatar'}>
															{artwork.artist.avatar_paths ? (
																<ResponsiveImage
																	initialSrc={artwork.artist.avatar_paths['24']}
																	initialSrcSet={`${artwork.artist.avatar_paths['24']} 1x,${artwork.artist.avatar_paths['48']} 2x`}
																	alt="Artist photo"
																	sources={[
																		{
																			srcset: [
																				{
																					src: artwork.artist.avatar_paths['24_webp'],
																					xd: "1x",
																				},
																				{
																					src: artwork.artist.avatar_paths['48_webp'],
																					xd: "2x",
																				}
																			],
																		},
																		{
																			srcset: [
																				{
																					src: artwork.artist.avatar_paths['24'],
																					xd: "1x",
																				},
																				{
																					src: artwork.artist.avatar_paths['48'],
																					xd: "2x",
																				}
																			],
																		}
																	]}
																/>
															) : (
																artwork.artist.name.substr(0, 1)
															)}
														</div>
														<div className={'artist-name'}>{artwork.artist.name}</div>
													</Link>

													<div className={'collection-auction'}>
														{artwork.last_price ? (<div className={'bid-start'}>last price</div>) : null}
														<div className={'bid'}>
															<div>
																{artwork.last_price ? (
																	<>
																		<div className={'fixed-with-gradient'}>{formatCCFixed(artwork.last_price, artwork.last_payment_token.decimals, 4).replace('.', ',')}
																		</div>
																		<div>{artwork.last_payment_token.symbol}</div>
																	</>
																) : (
																	<a href={artwork.permalink} rel="noopener" target="_blank" className={'not-on-sale'}>make an offer</a>
																)}
															</div>
															<a href={artwork.permalink} rel="noopener" target="_blank" className={'btn btn--secondary'}>OpenSea</a>
														</div>
													</div>

												</div>
											);
											return html;
										})
									) : (
										<div className={'container-fluid text-center my-5'}>Unable to load assets from OpenSea</div>
									)}
								</>
							)}
						</div>

						{/* Load More: start */}
						<div className={'load-more my-4 col-12 col-md-11 d-flex justify-content-center' + ((artworksLoader == null) || (artworksLoader != null && artworksList.length === artworksLoader.t) ? ' d-none' : '')}>
							<button className={'btn btn--secondary'} onClick={onLoadMoreArtworks}>
								Load More
								<div className={'btn-arrow'}></div>
							</button>
							<div className="load-more-count">
								{artworksLoader == null ? '...' : artworksLoader.t - artworksList.length}
							</div>
						</div>
						{/* Load More: end */}

					</div>
				</div>
			</section>



			<input type="checkbox" id={'toggle_filters_modal'} onChange={onToggleFilters} autoComplete="off" hidden />
			<section id={'filters_drops_page'} className={'filters-drops-page'}>
				<div className={'container-fluid'}>

					<div className={'title-container d-flex justify-content-center'}>
						<div className={'title col-10 my-3 my-lg-5'}>Filter by</div>
					</div>

					<div className={'d-flex justify-content-center'}>

						<div className={'filters-dropdown col-10 d-flex justify-content-between flex-column flex-md-row flex-wrap'}>


							<div className={'my-2'}>
								<div className={'my-2'}>
									Drop
								</div>

								<Select2
									ref={dropsListSelect}
									data={dropsList == null ? [{ value: '-', label: 'All drops' }] : [{ value: '-', label: 'All drops' }].concat(dropsList.i.map((drop) => {
										return {
											value: drop.slug,
											label: 'Drop #' + drop.number + ' - ' + drop.name,
										};
									}))}
									value={selectedDrop != null ? selectedDrop : '-'}
									update={onChangeFilterDrop}
									disabled={dropsList == null ? true : false}
								/>
							</div>

							<div className={'my-2'}>
								<div className={'my-2'}>
									Artist
								</div>

								<Select2 data={artistsList == null ? [{ value: '-', label: 'All artists' }] : [{ value: '-', label: 'All artists' }].concat(artistsList.i.map((artist) => {
									return {
										value: artist.slug,
										label: artist.name,
										component: (inp) => <span>{artist.avatar_paths ? <img src={artist.avatar_paths[24]} /> : ''} {artist.name}</span>
									}
								}))}
									value={'-'}
									update={onChangeFilterArtist}
									disabled={artistsList == null ? true : false}
									placeholder={artistsList == null ? 'Loading artists...' : 'Choose an artist'} />
							</div>

							<div className={'my-2'}>
								<div className={'my-2'}>
									Sort
								</div>

								<Select2 data={sortList}
									value={'d_desc'}
									update={onChangeFiltersSort}
								/>

							</div>


							{/* <Select2 data={dropsLoader == null ? [] : dropsLoader.i.map((drop) => {
								return {
									value: drop.slug,
									label: 'Drop #' + drop.number + ' - ' + drop.name,
								};
							})}

								// value={options}
								update={onChangeFilterDrop}
								disabled={dropsLoader == null ? true : false}
								placeholder={dropsLoader == null ? 'Loading drops...' : 'Choose a drop'}>
							</Select2> */}


							{/* <Select2 data={dropsLoader == null ? [] : dropsLoader.i.map((dropArtist) => {
								return {
									value: dropArtist.artist.slug,
									label: dropArtist.artist.name,
								};
							})}

								disabled={dropsLoader == null ? true : false}
								placeholder={dropsLoader == null ? 'Loading artist...' : 'Choose an artist'}>
							</Select2> */}



							{/* <Select2 data={sortList}
								placeholder={'Sort by'}>
							</Select2> */}

						</div>

					</div>

					<div className={'filter-btn-container d-flex justify-content-center'}>
						<div className={'col-10 d-flex justify-content-between justify-content-lg-end'}>
							<div className={'cancel-btn'}>
								<label htmlFor={'toggle_filters_modal'} className={'btn btn--secondary'}>
									Cancel
								</label>
							</div>
							<div className={'submit-btn'}>
								<label className={'btn btn--secondary'} onClick={onSubmitFilters} htmlFor={'toggle_filters_modal'}>
									<div className={'me-2'}>
										<img src={'/assets/img/icons/filters_icon_fff.svg'} alt={'alt-icon'} />
									</div>
									Filter
								</label>

							</div>
						</div>


					</div>

				</div>
			</section>


		</Layout>
	)
}

export default Drops
